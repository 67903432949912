import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const EnergyInfrastructurePage = () => {
  return (
    <Layout
      meta={{
        description:
          'Reduce churn, accelerate renewable projects & make your grid smarter through spatial analysis',
        title: 'Atlas for Energy & Infrastructure',
        type: 'website'
      }}
      title={'Atlas for Energy & Infrastructure'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Energy & Infrastructure'}
            description={
              'Reduce churn, accelerate renewable projects & make your grid smarter through spatial analysis'
            }
            video="https://cdn.atlas.co/landing-pages/energy-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>Optimize operations and efficiency</h2>
            <p>
              The future success of energy and infrastructure projects relies on
              smart technology investments and innovative solutions for a
              competitive edge.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/site-selection.mp4'}
            icon={{ src: '/icons/map-pin-area.svg', color: '#CDE4E1' }}
          >
            <h3>Site selection</h3>
            <p>
              For renewable energy developers, using multiple location data
              streams in the site selection process is crucial for understanding
              catchment and trade areas for final clients.
            </p>
            <p>
              Whether it's for solar farms, wind turbines, or hydro power
              projects, evaluating the best location for renewable energy
              projects can be the key to justifying pricing and closing deals
              over competitors.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/network-analysis.mp4'}
            reverse
            icon={{ src: '/icons/cell-tower.svg', color: '#FFF4E7' }}
          >
            <h3>Network Planning</h3>
            <p>
              With increasingly complex networks and limited resources, utility
              companies need alternatives to traditional GIS solutions that
              offer greater functionality, flexibility, and seamless
              connectivity to existing systems. Whether deploying new pipelines
              or modernizing your grid, Atlas can help you manage your network
              more efficiently.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/solar-estimates.mp4'}
            icon={{
              src: '/icons/solar-panel.svg',
              color: '#FFBF6E'
            }}
          >
            <h3>Energy production estimates</h3>
            <p>
              Visualize solar layout in 3D and make energy production estimates.
            </p>
            <p>
              Early estimations help you plan and optimize solar installations
              effectively, ensuring maximum efficiency and return on investment.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/shade-analysis-video.mp4'
            }
            reverse
            icon={{ src: '/icons/shade-analysis.svg', color: '#C2E5FF' }}
          >
            <h3>Shade analysis</h3>
            <p>
              Display the shade coverage or the number of sunlight hours on
              specific dates. Simply select the date you want to analyze for
              detailed insights.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default EnergyInfrastructurePage
